import React from 'react'


import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import "@trendmicro/react-sidenav/dist/react-sidenav.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleRoof , faHeadset , faRightFromBracket} from '@fortawesome/free-solid-svg-icons'

export default function SuperSideNav( { select } ) {
  return (
    <SideNav
    onSelect={(selected) => {
        console.log( selected )
        select( selected )
    }}
>
    <SideNav.Toggle />
    <SideNav.Nav defaultSelected="listacampana"  > 
        <NavItem  eventKey="datos">
            <NavIcon>
              <FontAwesomeIcon title='Lista de Campañas' icon={faPeopleRoof} style={{ fontSize: '1.75em' }}/>
                
            </NavIcon>
            <NavText>
                Liasta de Campañas
            </NavText>
        </NavItem>
        <NavItem eventKey="agentescampana">
            <NavIcon>
                {/* <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} /> */}
                <FontAwesomeIcon title='Agentes por campaña' icon={faHeadset} style={{ fontSize: '1.75em' }}/>
            </NavIcon>
            <NavText>
                Agentes Por Campaña
            </NavText>
            <NavItem eventKey="llamada">
                <NavText>
                    En Llamada
                </NavText>
            </NavItem>
            <NavItem eventKey="receso">
                <NavText>
                    En receso
                </NavText>
            </NavItem>

            <NavItem eventKey="calificando">
                <NavText>
                    Calificando
                </NavText>
            </NavItem>
        </NavItem>
        <NavItem eventKey="salir">
            <NavIcon>
              <FontAwesomeIcon title='Salir' icon={faRightFromBracket} style={{ fontSize: '1.75em' }}/>
                
            </NavIcon>
            <NavText>
               Salir
            </NavText>
        </NavItem>
    </SideNav.Nav>
  </SideNav>
  )
}
