import React from 'react'


import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import "@trendmicro/react-sidenav/dist/react-sidenav.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop  , faClock  , faCloudArrowUp , faPerson , faList , faStar } from '@fortawesome/free-solid-svg-icons'

import { useNavigate } from "react-router-dom";

export default function AdminSideNav( {select}) {

    const navigate = useNavigate() ;

  return (

    <SideNav
    onSelect={(selected) => {
        console.log( selected )
        if ( selected === "loadcampana")
        {
            navigate("/uploadfile") ;
            return ;
        }

        if ( selected === "loadcalifica")
        {
            navigate( "/uploadcalifica") ;
            return ; 
        }
    }}
>


    <SideNav.Toggle />
    <SideNav.Nav defaultSelected="calldetail"  >

        <NavItem  eventKey="calldetail">
             <NavIcon>
               <FontAwesomeIcon title='Detalle de Llamada' icon={faList} style={{ fontSize: '1.75em' }}/>
                
             </NavIcon>
             <NavText>
                 Detalle de Llamada
             </NavText>
         </NavItem> 

        <NavItem  eventKey="callhora">
             <NavIcon>
               <FontAwesomeIcon title='Llamada por Hora' icon={faClock} style={{ fontSize: '1.75em' }}/>
                
             </NavIcon>
             <NavText>
                 Llamada por Hora
             </NavText>
         </NavItem> 

         <NavItem eventKey="callagente">
             <NavIcon>
                
                 <FontAwesomeIcon title='Llamada Agente' icon={faPerson} style={{ fontSize: '1.75em' }}/>
             </NavIcon>
             <NavText>
                 Llamada Agente
             </NavText>
         </NavItem>


         <NavItem  eventKey="loadcampana">
             <NavIcon>
               <FontAwesomeIcon title='Cargar Campaña' icon={faCloudArrowUp} style={{ fontSize: '1.75em' }}/>
                
             </NavIcon>
             <NavText>
                 Cargar Campaña
             </NavText>
         </NavItem>

         <NavItem  eventKey="loadcalifica">
             <NavIcon>
               <FontAwesomeIcon title='Cargar Califica' icon={faStar} style={{ fontSize: '1.75em' }}/>
                
             </NavIcon>
             <NavText>
                 Cargar Calificaciones
             </NavText>
         </NavItem>

         <NavItem eventKey="monitor">
             <NavIcon>
               <FontAwesomeIcon title='Monitoreo Campaña' icon={faDesktop} style={{ fontSize: '1.75em' }}/>
                
             </NavIcon>
             <NavText>
                 Monitoreo Campaña
             </NavText>
         </NavItem> 

    </SideNav.Nav>
  </SideNav>


  )
}
