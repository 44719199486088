import React , {useState ,useEffect} from 'react'

import { ApiCallGet , ApiCallPost} from '../ApiCall/ApiCall' // Para traer las campañas


export default function UpLoadFile() {

    const [theData,setTheData] = useState([]) ;
    const [selectedIndex,setSelectedIndex] = useState( 0 ) ;
    const [file, setFile] = useState()
    const [typeError, setTypeError] = useState(null);
    const [data,setData] = useState( null )
    const [showButton,setShowButton] = useState( false )


    function handleSubmit(event)
    {
        event.preventDefault()
        const url = 'campanaup';
        //const formData = new FormData();
        ////formData.append('file', file);
        //formData.append('fileName', file.name);
        console.log( "Antes del campanaup = " + file.name  )
        // const config = {
        //   headers: {
        //     'content-type': 'multipart/form-data',
        //   },
        // };
        // axios.post(url, formData, config).then((response) => {
        //   console.log(response.data);
        // });

        console.log( "campain = " + selectedIndex + "  " + theData[selectedIndex].idcallcampains )

        data.campain = theData[selectedIndex].idcallcampains ; //???

        ApiCallPost( url , data  , 0 )
        .then((response) => {
            console.log( "Response = " + response.data )
            setTypeError('Se cargo la campaña con exito');
            setShowButton( false )
        }) 
        .catch((err) => {
            console.log( err )
        })
    

    }

    function handleChange(e) {


        setTypeError( null )

        if ( e.target.files[0] === undefined )
        {
            return ;
        }

        console.log( "El nombre del archivo es : " + e.target.files[0].name)

      setFile(e.target.files[0]) ; // Aqui si dejamos el nombre del archivo

      let fileTypes = ['text/csv'];
 
      let selectedFile = e.target.files[0]; // Nombre del archivo
      if(selectedFile){
  
          console.log( selectedFile.type ) ;
  
          
  
        if(selectedFile&&fileTypes.includes(selectedFile.type)){
          setTypeError(null);
          let reader = new FileReader();
  
          reader.readAsText( selectedFile , "ISO-8859-2") ;
          //reader.readAsArrayBuffer(selectedFile);
          reader.onload= async(e1)=>{
  
              console.log( "Antes de imprimir") ;
  
  
              //console.log(   e1.target.result  ) ;
  
              var data =  { 
                body : e1.target.result , 
                fileName : e.target.files[0].name }
  
                console.log( "Antes de setData name = " + e.target.files[0].name )
              setData( data ) ;
              setShowButton( true )
  
  
          }
        }
        else{
          setTypeError('Favor de seleccionar archivo csv');
          //setExcelFile(null);
        }
      }
      else{
        console.log('Please select your file');
      }
  



    }

    useEffect(() => {

        ApiCallGet( "campanas")
        .then((response) => {
            console.log( response) ;

            showSelect( response )

        })
        .catch((err) => {
            console.log( err ) ;
        })



    } ,[])


    function showSelect( dataArray)
    {
     console.log( "setTheData = " + JSON.stringify( dataArray ) )

        setTheData( dataArray ) // Por lo pronto , no lo usamos

        var select = document.getElementById('select') ;


        while( select.length > 0 )
        {
            select.remove( select.length -1 ) ;
        }


        for( var i = 0 ; i < dataArray.length ; i++)
        {
            

            var option = document.createElement("option");
            option.value = dataArray[i].idcallcampains  ;
            option.text = dataArray[i].nombre  ;
            select.appendChild(option)


        }



    }
    

    

    function onSelectChange(e)
    {

        var campain = document.getElementById( "select")
        
        console.log( "Campaña = " + campain.selectedIndex) ;

        setSelectedIndex( campain.selectedIndex ) ;

    }

  return (
    <div  style={{marginLeft:'400px' , marginRight:'400px' , borderWidth : '1px' , borderStyle :'solid' ,
             marginTop : '20px' , height:'300px' , borderRadius : '10px'}}>


        <div className='flow' style={{textAlign:'center'}}>

            <h4>Cargar Datos Campaña</h4>

        </div>


        <div  style={{textAlign:'center' , width:'40%' , margin:'auto'}}>
            <select onChange={onSelectChange} id="select" class="form-select" aria-label="Default select example">
                <option selected>Seleccione Campaña</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
        </div>

        <form className='mt-4' onSubmit={handleSubmit}>
            <div  style={{textAlign:'center' , width:'80%' , margin:'auto'}}>
                <div class="mb-3">
                <label for="formFile" class="form-label">Cargar CSV</label>
                <input class="form-control" type="file" onChange={handleChange} id="formFile"/>
                </div>
                {
                    showButton && (
                        <button type="submit" class="btn btn-primary">Cargar Archivo</button>
                    )
                }
                
            </div>
        </form>

        {typeError&&(
          <div className="alert alert-danger" role="alert">{typeError}</div>
        )}



        

    </div>
  )
}
