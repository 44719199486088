import React , { useState , useEffect } from 'react'
import UpCalificaEntry from './UpCalificaEntry'





import UpAlert01 from './UpAlert01'

export default function UpLoadCalifica() { // Se cargan calificaciones para campaña

  const [alert01,setAlert01] = useState( false )
  const [theMessage,setTheMessage] = useState( "")
  const [theData,setTheData] = useState([])

  function onMessage(val)
  {
    console.log( "onMessage = " + val ) ;
    setTheMessage( val ) ;
  }

  function onSelected( value )
  {
    //setAlert01( true )
    
    if ( value.message !== "" )
    {
      setTheMessage( value.message ) ;
      setAlert01( true ) ;
    }
    else {
      setTheMessage( value.message ) ;
      setAlert01( false ) ;
      validateData( value ) ;

    }

  }

  function validateData( value )
  {

    setTheData( (array) => [...array, value.value])

    /*
        var e1 = document.getElementById('select') ;
    
    
        //var text = e1.options[e1.selectedIndex].text;


        //setData( dataArray[e1.selectedIndex])


        document.getElementById( "tercer").value = theData[e1.selectedIndex].rfc.substring( 0 , 4 )
        document.getElementById( "nombre").value = theData[e1.selectedIndex].nombre  + " "  +  theData[e1.selectedIndex].appaterno  + " " +  theData[e1.selectedIndex].apmaterno  ;

    */

  }

  useEffect(()=> {

    showSelect( theData )

  } ,[theData])

  useEffect(() => {



  } , [])

  function onCloseSelected()
  {
    setAlert01( false )
  }

  function onSelectChange()
  {
    console.log( "onSelectChange")
  }

  function showSelect( dataArray)
  {
      //setTheData( dataArray )

      var select = document.getElementById('select2') ;


      while( select.length > 0 )
      {
          select.remove( select.length -1 ) ;
      }


      for( var i = 0 ; i < dataArray.length ; i++)
      {
          

          var option = document.createElement("option");
          option.value = (i+1).toString() ;
          option.text = dataArray[i]   ;
          select.appendChild(option)


      }



  }



  return (
    <div  style={{marginLeft:'300px' , marginRight:'300px' , borderWidth : '1px' , borderStyle :'solid' ,
    marginTop : '20px' , height:'300px' , borderRadius : '10px'}}>


      <div className='flow' style={{textAlign:'center'}}>

        <h4>Calificación de  Campaña</h4>

      </div>


      <div  style={{textAlign:'center' , width:'40%' , margin:'auto'}}>
        <select onChange={onSelectChange} id="select" class="form-select" aria-label="Default select example">
            <option selected>Seleccione Campaña</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
        </select>
      </div>

      <div className='mt-2 ms-5'>
        <UpCalificaEntry selected={onSelected} message={(val) => onMessage(val) }></UpCalificaEntry>
      </div>


      
      <div  style={{textAlign:'center' , width:'40%' , margin:'auto'}}>
        <select onChange={onSelectChange} id="select2" class="form-select" aria-label="Default select example">
            <option selected>Calificaciones</option>
            {/* <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option> */}
        </select>
      </div>

      {
        alert01 && (
          <div>
            <UpAlert01 close={onCloseSelected} message={theMessage}></UpAlert01>
          </div>

        )
      }

      

    </div>      

  )
}
