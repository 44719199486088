import React from 'react'

export default function AdminCallingDetail() {
  return (
    <div style={{height:'25px' , borderWidth:'1px' , borderStyle:'solid'}}>
        <p>Datos llamada</p>
        
    </div>
  )
}
