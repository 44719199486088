import React from 'react'


import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import "@trendmicro/react-sidenav/dist/react-sidenav.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume , faPhoneSlash , faRightFromBracket , faCalendarDays} from '@fortawesome/free-solid-svg-icons'

export default function AgentSideNav( { select } ) {
  return (
    <SideNav
    onSelect={(selected) => {
        console.log( selected )
        select( selected )
    }}
>
    <SideNav.Toggle />
    <SideNav.Nav defaultSelected="ultimallamada"  >
        <NavItem  eventKey="datos">
            <NavIcon>
              <FontAwesomeIcon title='Ultimas llamadas' icon={faPhoneVolume} style={{ fontSize: '1.75em' }}/>
                
            </NavIcon>
            <NavText>
                Ultimas llamadas
            </NavText>
        </NavItem>
        <NavItem eventKey="colgarllamada">
            <NavIcon>
                {/* <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} /> */}
                <FontAwesomeIcon title='Login' icon={faPhoneSlash} style={{ fontSize: '1.75em' }}/>
            </NavIcon>
            <NavText>
                Fin Llamada
            </NavText>
            <NavItem eventKey="calificar">
                <NavText>
                    Calificar
                </NavText>
            </NavItem>
            <NavItem eventKey="colgar">
                <NavText>
                    Colgar
                </NavText>
            </NavItem>

            <NavItem eventKey="receso">
                <NavText>
                    Descanso
                </NavText>
            </NavItem>

        </NavItem>
        <NavItem eventKey="agendar">
                <NavIcon>
                    {/* <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} /> */}
                    <FontAwesomeIcon title='Agendar Llamada' icon={faCalendarDays} style={{ fontSize: '1.75em' }}/>
                </NavIcon>
                <NavText>
                    Agendar Llamada
                </NavText>
        </NavItem>
        <NavItem eventKey="salir">
            <NavIcon>
              <FontAwesomeIcon title='Salir' icon={faRightFromBracket} style={{ fontSize: '1.75em' }}/>
                
            </NavIcon>
            <NavText>
               Fin de Sesión
            </NavText>
        </NavItem>
    </SideNav.Nav>
  </SideNav>
  )
}
