import React from 'react'

export default function AdminCallingHeader() {
  return (
    <div >
        <div className='row' style={{borderWidth:'1px' , borderStyle:'solid' , backgroundColor :'darkgray' , color:'white' ,
        paddingTop :'1px' ,  borderRadius : '10px' , height : '30px'}}>

            <div className='col-3'>
                <p>Estado</p>
            </div>

            <div className='col-3'>
                <p>Num Tel</p>
            </div>

            <div className='col-3'>
                <p>Troncal</p>
            </div>

            <div className='col-3'>
                <p>Desde</p>
            </div>



        </div>
        
    </div>
  )
}
