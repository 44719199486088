import React from 'react'

/*
{
    lineText1 : "Total Llamadas" ,
    value1 : "6971" ,
    lineText2 : "Total Llamadas" ,
    value2 : "0" ,
    lineText3 : "Llamadas Conectadas" ,
    value3 : "0"

}, */

function AdminHeaderRows( {data}) {
  return (
    <div>
        <div className='row' style={{height:'25px'}}>

            <div className='col-4'>
                <div className='row'>

                    <div className='col-10'>

                        <p>{data.lineText1}</p>

                    </div>

                    <div className='col-2'>
                        <p style={{fontWeight:'bold'}}>{data.value1}</p>
                    </div>

                </div>

            </div>



            <div className='col-4'>
                <div className='row'>

                    <div className='col-9'>

                        <p>{data.lineText2}</p>

                    </div>

                    <div className='col-3'>
                        <p style={{fontWeight:'bold'}}>{data.value2}</p>
                    </div>

                </div>

            </div>



            <div className='col-4'>
                <div className='row'>

                    <div className='col-10'>

                        <p>{data.lineText3}</p>

                    </div>

                    <div className='col-2'>

                        <p style={{fontWeight:'bold'}}>{data.value3}</p>

                    </div>

                </div>

        </div>


    </div>



    </div>



  )
}

export default AdminHeaderRows