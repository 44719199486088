



export default function getGlobal()
{

    //return "https://my-evb.com/hhback/"  // Este es el acceso global

    //urlBack : 'https://hhback.hunterhire.io/hhback/' // Para produccion

    //console.log( "regresando http://localhost:3005/hhback/"  ) // Hola

    console.log( "production2 = " + process.env.REACT_APP_production) ;

    var value = true  ;

    //if ( process.env.REACT_APP_production==="false" )
    if ( !value )
    {
        return  "http://localhost:3037/callcenter/"
    }
    else{
        return  'https://digital.currenmexico.com/callcenter/' // Aqui estariamos en el servidor
    }
    


}