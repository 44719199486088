import React , { useState , useEffect }  from 'react'

import AdminHeader from './AdminHeader'

import AdminMonitor from './AdminMonitor/AdminMonitor'

import { useNavigate , useLocation } from "react-router-dom";

function AdminDash({  theData}) {

  const navigate = useNavigate() ;

  const location = useLocation() ;

  useEffect(() => {

    console.log( "nav = " + JSON.stringify( location.state ) )

    if ( location.state === null )
    {
      navigate( "/")
      return ;
    }

  } , [])

  return (
    <div  style={{marginLeft:'200px' , width :'80%' , marginTop : '20px' , height:'300px'}}>
        <AdminHeader theData={theData}></AdminHeader>
        <AdminMonitor></AdminMonitor>
    </div>
  )
}

export default AdminDash