import React from 'react'
import AdminCallingHeader from './AdminCallingHeader'
import AdminCallingDetail from './AdminCallingDetail'

var listCalls = [
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    }



]

export default  function AdminCalling() {
  return (
    <div  style={{paddingLeft:'20px'}} >
        <AdminCallingHeader></AdminCallingHeader>

        <div style={{ overflowY :'auto' , height : '55vh'}}>

            {
                listCalls.map(() =>
                    
                        <AdminCallingDetail></AdminCallingDetail>
                    

                    
                )
            }
        
        </div>


    </div>
  )
}

//export default AdminCalling