import React , { useState , useEffect } from 'react' // Aqui imprimimos los datos de una llamada

import { ApiCallGet } from '../../ApiCall/ApiCall'

export default function CallData() {

    const [theData,setTheData] = useState( [] ) ; // 
    const [render,setRender] = useState( [])


    var theIndex = 0 ;

    function renderData()
    {


        var elemArray = []

        for( var i = 0 ; i < theData.length ; i+=2 )
        {
            console.log ( "index = " + i ) ;


            console.log( "field = " + theData[i].field )

            var elem = buildElem( i ) ;

            elemArray.push( elem ) ;


        }

        console.log( "Antes de render") ; 

        setRender( elemArray ) ;

        function buildElem( index)
        {

                var elem =      <div className='row'>

                          <div className='col-2'>
                              <p>{theData[index].field}</p>
                          </div>

                          <div className='col-3'>
                             <p style={{fontWeight:'bold'}}>{theData[index].value}</p>
                         </div>

                          <div className='col-2'>
                              <p> { theData[index+1] !== undefined ? theData[index+1].field : <p></p> }</p>
                          </div>

                          <div className='col-3'>
                             <p style={{fontWeight:'bold'}}>{ theData[index+1] !== undefined  ? theData[index+1].value : <p></p>}</p>
                         </div>


                     </div>

                return elem ;
            }

    }

    useEffect(() =>{


        console.log( "Nos llego theData") ;

        renderData() 



    } ,[theData])

    useEffect(() => { 


        ApiCallGet( "getdatajson/?index=1&campain=2")
        .then((response) => {

            console.log( "call response = " + response[0].telefono  ) ;

            

            //setTheData( response ) ;
            fillData( response )

        })
        .catch((err) => {
            console.log( err)
        })

        


    } , [])


    function fillData( data )
    {

        var theJson = JSON.parse( data[0].data )

        var len = Object.keys(theJson).length ; // Object.keys(obj).length

        var arr = [] ;

        var keys = Object.keys( theJson ) ;

        for( var i = 0 ;i < len ; i++ )
        {
            var obj = {
                field : keys[i] ,
                value : theJson[keys[i]]
            
            }

            arr.push( obj ) ;

        }

        console.log( "Antes de setData")

        setTheData( arr ) ;
    }


  return (
    <div style={{overflowY:'auto', height:'70vh'}}>
        <ul>
        {
            render.length > 0 &&
            (
                render.map((value, index ) =>
                    value 
                )
            )
            //theData.length > 0 && (


               

                // theData.map( ( val , index , data ) =>
                          
                //     <div className='row'>

                //          <div className='col-4'>
                //              <p>{val.field}</p>
                //          </div>

                //          <div className='col-6'>
                //             <p style={{fontWeight:'bold'}}>{val.value}</p>
                //         </div>

                //     </div>

                //  )
                

            //) 

    
        }            
        </ul>

    </div>
  )
}
