// nanejo de campos del estatus de la campaña
import React from 'react'


import AdminHeaderRows from './AdminHeaderRows'


var infoArray = [

    {
        lineText1 : "Total Llamadas" ,
        value1 : "6971" ,
        lineText2 : "Total Llamadas" ,
        value2 : "0" ,
        lineText3 : "Llamadas Conectadas" ,
        value3 : "0"

    },
    {
        lineText1 : "Llamadas por realizar" ,
        value1 : "5215" ,
        lineText2 : "Llamadas Siendo Marcadas" ,
        value2 : "0" ,
        lineText3 : "Llamadas Siendo Timbradas" ,
        value3 : "0"
 
    },
    {
        lineText1 : "Llamadas fallidas" ,
        value1 : "321" ,
        lineText2 : "Llamadas No contestadas" ,
        value2 : "721" ,
        lineText3 : "Llamadas Abandonadas" ,
        value3 : "345"
 
    },
    {
        lineText1 : "Llamadas Cortas" ,
        value1 : "512" ,
        lineText2 : "Promedio Duración Llamada" ,
        value2 : "00:01:18" ,
        lineText3 : "Llamadas abandonadas" ,
        value3 : "121"
 
    },



    

    
]



function AdminHeaderFields({data}) {
  return (
    <div  style={{paddingLeft:'25px' , paddingRight : '25px' , paddingTop :'5px' }}>

        <div className='row' style={{height:'25px'}}>

            <div className='col-3'>

                <p>Cola</p>

            </div>

            <div className='col-3'>

                <p style={{fontWeight:'bold'}}>{data.cola}</p>

            </div>


            <div className='col-3'>

                <p>Intentos</p>

            </div>

            <div className='col-3'>

                <p style={{fontWeight:'bold'}}>{data.intentos}</p>

            </div>


        </div>

        {
            infoArray.map((data) => 
             <AdminHeaderRows data={data}></AdminHeaderRows>
         )
           
        }

        

        

    </div>
  )
}

export default AdminHeaderFields