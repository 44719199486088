
import axios from 'axios'
import getGlobal from './setglobal';





function ApiCallGet( url  ) // La url es el end point
{
    return new Promise(( resolve,reject) => {

        console.log( "Por el ApiCall get " + url ) ;

        var fullGlobal = getGlobal() + url  ;


        //axios.get( "http://127.0.0.1:3004/livefactura/" + url , {
        axios.get( fullGlobal , {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            console.log( "LLego la respuesta " + JSON.stringify( response.data ) )
            resolve(response.data) ;
          })
          .catch((err) =>{
            console.log( err) ;
            resolve() ;
          })
          


    })


}

function ApiCallPost( url , data  , head = 0) // La url es el endpoint
{


    //var url = filterUrl( url2 ) ;


    console.log( "ApiCallPost url = " + url )

    //console.log( "Llamando a ApiCallPost") ;

    return new Promise(( resolve,reject) => {

        console.log( "Por el ApiCall " +  data   ) ;


        var fullGlobal = getGlobal() + url  ;


        console.log( "getGlobal = " + fullGlobal )
        console.log( "url = " + url ) ;

        var theHeader = {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }

        if ( head === 1 )
        {
          theHeader = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data'
          }

        }



        //axios.post( "http://localhost:3004/livefactura/" + url ,  JSON.stringify( data  )    , {
          axios.post( fullGlobal ,  JSON.stringify( data  )    , {
            headers: theHeader
          })
          .then((response) => {
            console.log( "LLego la respuesta " + response.data )
            resolve(response.data) ;
          })
          .catch((err) =>{
            console.log( err) ;
            resolve() ;
          })
          


    })



}


function getFormatDate(d) {
  var date = new Date(d);
  var dateStr =
      date.getFullYear() + "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
      ("00" + date.getDate()).slice(-2) + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2) + ":" +
    ("00" + date.getSeconds()).slice(-2);
  //console.log(dateStr);

  return dateStr;
}


//module.exports = { ApiCallGet , ApiCallPost } ;

export { ApiCallGet , ApiCallPost , getFormatDate }