import React from 'react'

export default function UpCalificaEntry( { selected  }) {

    function onSelected()
    {
        //var value = "" ;
        var indice = document.getElementById("indice").value ;
        var value = document.getElementById("valor").value ;


        if ( indice === "" || value === "" )
        {

            var obj = {
                indice : indice ,
                value : value ,
                message : "No se capturo campo" 
            }

            selected( obj ) ;
            return ;

        }


        obj = {
            indice : indice ,
            value : value ,
            message : "" 
        }

        selected( obj ) ;

    }

  return (
    <div>

        <div className='row'>

            <div className='col-1'>

                <p style={{marginTop:'7px'}}>Indice</p>

            </div>

            <div className='col-2'>

                <div class="input-group mb-3">
                    <input type="number" class="form-control" id="indice" min="1" max="50"
                        placeholder="Indice" aria-label="Username" aria-describedby="basic-addon1"/>
                </div>


            </div>

            <div className='col-2'>
                <p style={{marginTop:'7px'}}>Valor</p>
            </div>

            <div className='col-4'>
                <div class="input-group mb-3">
                        <input type="text" class="form-control" id="valor" 
                            placeholder="valor" aria-label="Username" aria-describedby="basic-addon1"/>
                </div>
            </div>

            <div className='col-1'>

            <button type="button" onClick={onSelected} class="btn btn-primary">Agregar</button>

            </div>

        </div>
        
    </div>
  )
}
