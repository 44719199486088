import React from 'react'

import AdminCalling from './AdminCalling'
import AdminAgents from './AdminAgents'

export default  function AdminMonitor() {
  return (
    <div style={{borderWidth:'1px' , borderStyle:'solid' , paddingTop :'10px' , borderRadius : '10px' , height :'62vh'}}>
        
        <div className='row'>

            <div className='col-6'>
                {/* <p>Calling</p> */}
                <AdminCalling></AdminCalling>

            </div>

            <div className='col-6'>
                {/* <p>Agentes</p> */}

                <AdminAgents></AdminAgents> 

            </div>


        </div>
    </div>
  )
}

//export default AdminMonitor