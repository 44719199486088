import React from 'react'


import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import "@trendmicro/react-sidenav/dist/react-sidenav.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse , faRightToBracket , faRightFromBracket} from '@fortawesome/free-solid-svg-icons'

import { Navigate, useNavigate } from "react-router-dom";

export default function HomeSideNav( { select }) {

    const navigate = useNavigate();

  return (

   


    
    <SideNav 
    onSelect={(selected) => {
        console.log( selected )

        if ( selected === "home")
        {
            navigate("/")
            return ;
        }

        select( selected ) ;
        
    }}
   
>
    <SideNav.Toggle />
    <SideNav.Nav defaultSelected="home"  >
        <NavItem  eventKey="home">
            <NavIcon>
              <FontAwesomeIcon title='Home' icon={faHouse} style={{ fontSize: '1.75em' }}/>
                
            </NavIcon>
            <NavText>
                Home
            </NavText>
        </NavItem>
        <NavItem eventKey="login">
            <NavIcon>
                {/* <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} /> */}
                <FontAwesomeIcon title='Login' icon={faRightToBracket} style={{ fontSize: '1.75em' }}/>
            </NavIcon>
            <NavText>
                Login
            </NavText>
            <NavItem eventKey="admin">
                <NavText>
                    Admin
                </NavText>
            </NavItem>
            <NavItem eventKey="supervisor">
                <NavText>
                    Supervisor
                </NavText>
            </NavItem>

            <NavItem eventKey="agente">
                <NavText>
                    Agente
                </NavText>
            </NavItem>
        </NavItem>
        <NavItem eventKey="home">
            <NavIcon>
              <FontAwesomeIcon title='Salir' icon={faRightFromBracket} style={{ fontSize: '1.75em' }}/>
                
            </NavIcon>
            <NavText>
                Salir
            </NavText>
        </NavItem>
    </SideNav.Nav>
  </SideNav>
  )
}
