import React , {useEffect,useState} from 'react'



import DigLogo from '../../images/digital01.jpg'
import TelLogo from '../../images/digital02.jpg'


import AdminSideNav from '../Admin/AdminSideNav';
import HomeSideNav from '../Admin/HomeSideNav';
import AgentSideNav from '../Admin/AgentSideBar';
import SuperSideNav from '../Admin/SuperSideNav';


import { useNavigate } from "react-router-dom";


function Header( {hideNav=false , setLogin }) {
const [theHide,setTheHide] = useState( false )
const [sideNav,setSideNav] = useState( "home")
const [theLogin,setTheLogin] = useState( {} )





const navigate = useNavigate()

useEffect(() => {

  console.log( "Cambio de login " + setLogin.user)

  if ( Object.keys(setLogin).length === 0 )
  {
    return ; // No hacemos nada
  }

  setTheLogin( setLogin ) ;

  if ( setLogin.type === 1 )
  {
    console.log( "Header para admin " ) 
    navigate("/admindash" , { "state" : setLogin} ) ;
    setSideNav( "admin" )
    return ;
  }


  if ( setLogin.type === 2 )
  {
    setSideNav( "supervisor" )
    return ;
  }

  if ( setLogin.type === 3 )
  {
    navigate("/adminagentdash") ;
    setSideNav( "agente" )
    return ;
  }



} ,[setLogin])

function onHomeSelect( val , setLogin )
{
  console.log( "Header select = " + val ) ;

  if ( ["admin","supervisor" ,"agente" ].includes( val ) ) // En cualquiera de las tres se loeguea
  {
    onLogin() ;
    return ;
  }

  setSideNav( val )
}


function onLogin()
{
  console.log( "onLogin*****") ;
  navigate('/login')
  console.log( "Despues de navigate") ;
}

useEffect(()=>{


  console.log( "Se solicita hide = " + hideNav ) ;

  //setTheHide( hideNav)

},[hideNav])

  
  return (
    <div className='ps-5' >


    <div >

    

    <div className='row'>

        <div className='col-2'>

        </div>

        <div className='col-3'>

            <img src={DigLogo} height='80px' alt='uno'/>




        </div>

        <div className='col-3' >
                <h1>Digital Call Center</h1>
        </div>

        <div className='col-3'>


            <img src={TelLogo} height='80px' alt='uno'/>

        </div>

    </div>

    </div>



    

    

        {/* <div className='nav  mt-0' style={{margin:'auto' , width : '25%' }} >

            {
              !theHide && (

                <div className="">
                  <ul>
                    <li> <Link to="/">Home</Link> </li>
                    <li> <Link to="/login" >Login</Link> </li>
                    
                    <li> <Link to="/home">Logout</Link> </li>
                  </ul>
                </div>
              )

            }

        </div> */}

        
        {
          sideNav === "home" && (
             <HomeSideNav select={onHomeSelect}></HomeSideNav>
          )

        }

        {
          sideNav === "admin" && (

            //<p>Hola</p>

            <AdminSideNav select={onHomeSelect}></AdminSideNav>
          )

        }
         
        {
          sideNav === "agente" && (
             <AgentSideNav select={onHomeSelect}></AgentSideNav>
          )

        }

        {
          sideNav === "supervisor" && (            
             <SuperSideNav select={onHomeSelect}></SuperSideNav>
          )

        }
        
        
        
    </div>
  )
}

export default Header