import React from 'react'

export default function UpAlert01( {close , message }) {

    function cerrar()
    {
        console.log( "Error ")
        close() ;
    }

  return (
    <div className='w-50' style={{alignItems:'center' , margin:'auto'}}>
    <div class="alert alert-warning  alert-dismissible fade show ">
        <strong>Atencion!</strong>{message} &nbsp;&nbsp;

        {/* <button type="button rounded" style={{borderRadius:'10px' , backgroundColor :'lightgrey'}} onClick={aceptar}>Aceptar</button> */}
        <button type="button" class="btn-close"
          onClick={cerrar} data-bs-dismiss="alert"></button>
    </div>
    </div>

  )
}
