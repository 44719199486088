import React from 'react'
import AdminAgentHeader from './AdminAgentHeader'

import AdminAgentsDetail from './AdminAgentsDetail'


var listAgents = [
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    }





]


export default function AdminAgents() {
  return (
    <div style={{paddingRight:'20px'}}>
        <AdminAgentHeader></AdminAgentHeader>

        <div style={{ overflowY :'auto' , height : '55vh'}}>

            {

                listAgents.map(() => 
                    <AdminAgentsDetail></AdminAgentsDetail>
                    
                )
            }
        </div>

    </div>
  )
}

//export default AdminAgents