import React from 'react'

import CallData from './CallData/CallData'

export default function AdminAgentDash() {
  return (
    <div  >
        <div  style={{marginLeft:'100px' , width :'90%' , marginTop : '20px' , height:'80vh' ,
            borderWidth:'1px' , borderStyle:'solid' , paddingTop :'5px' , display:'inline-block' ,
            borderRadius : '10px'}}>

            <div className='row'>
              <div className='col-4 ms-4'>
                <p style={{fontWeight:'bold'}} >Llamada</p>
              </div>

              <div className='col-6'>
                  <div className='col-sm-6'>
                          {/* <label htmlFor="uso">Cafifica Llamada:</label> */}
                          <select class="form-select" id="uso" aria-label="Default select example">
                              <option selected>Seleccione Calificación</option>
                              <option value="0">No se ha Llamado</option>
                              <option value="1">Ya se marco pero no contesto</option>
                              <option value="2"> ya contesto</option>
                              <option value="1">Ya se marco pero no contesto</option>
                              <option value="1">Ya se marco pero no contesto</option>
                              <option value="1">Ya se marco pero no contesto</option>
                              <option value="1">Ya se marco pero no contesto</option>
                              <option value="1">Ya se marco pero no contesto</option>
                              <option value="1">Ya se marco pero no contesto</option>
                              <option value="1">Ya se marco pero no contesto</option>
                              <option value="1">Ya se marco pero no contesto</option>

                          </select>                
                    </div>

              </div>
              
            </div>
            


            <CallData></CallData>
        </div>


    </div>
  )
}
