import React , { useState , useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faHeadphones} from '@fortawesome/free-solid-svg-icons'

import AdminHeaderFields from './AdminHeaderFields'



var dataArray = [
    {
        value :1 ,
        text : "Naranja01" ,
        cola : '1000' ,
        intentos : "2"

    },
    {
        value :2 ,
        text : "Naranja02" ,
        cola : '2000' ,
        intentos : "3"

    },
    {
        value :3 ,
        text : "Naranja03" ,
        cola : '3000' ,
        intentos : "1"

    }
]

 export default function AdminHeader ( ) {

    const [data,setData] = useState( [] )


    useEffect(() => {

        setData( dataArray )

        displaySelect()

        onChangeSelect() 

    } ,[])



    function displaySelect( )
    {
        //var select = document.getElementById('select') ;

        var select = document.getElementById('select') ;


        while( select.length > 0 )
        {
            select.remove( select.length -1 ) ;
        }


        for( var i = 0 ; i < dataArray.length ; i++)
        {
            

            var option = document.createElement("option");
            option.value = dataArray[i].value ;
            option.text = dataArray[i].text ;
            select.appendChild(option)


        }


    }

    function onChangeSelect(  )
    {
        // var val = document.getElementById( "select").text ;

        // console.log( "Val = " + val)



        var sku = document.getElementById('select').value ;

        var e1 = document.getElementById('select') ;
    
    
        //var text = e1.options[e1.selectedIndex].text;


        setData( dataArray[e1.selectedIndex])

        
    
        console.log( sku ) ;
    
    }


  return (
    <div style={{borderWidth:'1px' , borderStyle:'solid' , paddingTop :'5px' , borderRadius : '10px'}}>
        <div className='row' style={{height:'25px'}}>

            <div className='col-1'>

            </div>

            <div className='col-1'>

            <FontAwesomeIcon title='Monitoreo de Campaña' icon={faHeadphones} style={{ fontSize: '1.75em' , height:'22px'}}/>

            </div>

            <div className='col-4'>
                <p>Monitoreo de Campaña :</p>
            </div>

            <div className='col-4'>
            <select id="select" onChange={onChangeSelect} class="form-select" aria-label="Default select example">
                <option selected>Seleccione Campaña</option>
                <option value="1">Naranja01</option>
                <option value="2">Naranja02</option>
                <option value="3">Naranja03</option>
            </select>

            </div>
            
        </div>

        <AdminHeaderFields data={data}></AdminHeaderFields>

        



    </div>
  )
}
