import React , {useEffect} from 'react'
//import { useBeforeunload } from 'react-beforeunload';

function Agentes( {hide}) {


  useEffect(() => {

    hide( true )

} ,[])

  // useBeforeunload((evt) => {
  //   evt.preventDefault() ;
  //   console.log( "Vas a parder tus datos") })


  return (
    <div>Agentes</div>
  )
}

export default Agentes