import React , {useState, useEffect } from 'react'


import { useNavigate } from "react-router-dom";


import {  ApiCallPost  } from '../ApiCall/ApiCall';

//import AdminSideNav from '../Admin/AdminSideNav';




function Login({ addMember , hide }) {

    const [errorMessage,setErrorMessage] = useState("") ;
    //const [isReenter,setIsReenter] = useState( false ) ;
    const [usuario,setUsuario] = useState("")
    const [password,setPassword] = useState( "" ) ;


    const navigate = useNavigate();

    function onSalir()
    {
        navigate( "/") ;
    }




    function handleSubmit(e)
    {
        e.preventDefault() ;

        console.log( "Handle submit ")


        var obj = {
            user : usuario ,
            password : password
        }

        ApiCallPost('login' , obj )
        .then((response) => {

            console.log( "Llegando respuesta") ;

            console.log( response ) ;

            processReponse( response ) ;


        })
        .catch((err) => {
            console.log( err ) ;
        } )


    }


    function processReponse( answer )
    {
        if ( answer.state === 1 )
        {
            console.log( "Usuario activo")

            processAdmin( answer )
            return ;
        }
        else{
            console.log( "El usuario no esta activo") ;
        }
    }

    function processAdmin( answer )
    {
        addMember( answer )
        
    }


    function userChange()
    {
        var pass = document.getElementById( "user" ).value ;

        setUsuario( pass ) ;
    }

    function passwordChange(e)
    {
        var pass = document.getElementById( "password").value ;

        setPassword( pass )


    }

  return (

    //  <div className='nav  mt-0' style={{margin:'auto' , width : '25%' }} >

    // top:'150px' , right:'35%' , backgroundColor :'lightgray' , position : 'fixed' , zIndex : 200 , borderRadius : '20px'

    <div className='box-login ' style={{ margin:'auto' , width : '25%'  , 
        backgroundColor :'lightgray'   , borderRadius : '20px'}} >



    <div className='d-flex flex-column align-items-center' 
        style={{borderWidth:2 , borderColor :'black' , fontFamily : 'Satoshi-Bold'}}>

        <div>
            <p className='tx-color-purple body-1'>Login</p>
        </div>

        <div className='display-label-none w-100'>       

            


            <form id='myForm form-2' onSubmit={handleSubmit} 
                style={{color:'black' , textAlign :'left' , fontSize :'12px'}}>

                {/* <Email email={'samuel_etelco@hotmail.con'}></Email> */}
        
                <div className=' m-4 mb-2'  >

                    {/* <div className="form-group m-4"> */}
                        <label htmlFor='user' >Usuario</label>
                        <input type="text" id="user" onChange={userChange}  
                            className="form-control" placeholder="Usuario"/>
                        
                    {/* </div> */}
                </div>
                
                <div className="form-outline m-4 mb-2 mt-2"  >
                    <label  htmlFor="password">Contraseña</label>
                    <input type="password" id="password" onChange={passwordChange} 
                        className="form-control" placeholder="Contraseña"/>
                    
                </div>






                <div className='row'>

                    <div className='col-1'>

                    </div>
                    <div className='col-4'>
                        <button type="submit"  className="button-2 m-4">Registrar</button>
                    </div>
                    <div className='col-1'>

                    </div>
                    

                    <div className='col-4'>
                        <button type="button" onClick={onSalir} className="button-2 m-4">Salir</button>
                    </div>

                    <div className='col-2'>

                    </div>
                    
                </div>

                {errorMessage && <div className="frame3 text-center"> {errorMessage} </div>}

            </form> 
        </div>
    </div>
</div>

  )
}

export default Login