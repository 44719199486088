import React , { useState} from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import './styles/styles.css'
import Header from './Components/Header/Header';
import Login from "./Components/Login/Login";
import Agentes from "./Components/Agentes/Agentes";
import AdminDash from "./Components/AdminCode/AdminDash";
import AdminAgent from "./Components/AdminCode/AdminAgent";
import AdminAgentDash from "./Components/AdminCode/AdminAgentDash";
import UpLoadFile from "./Components/UploadFile/UpLoadFile";
import UpLoadCalifica from "./Components/UpLoadCalifica/UpLoadCalifica";

//import { useNavigate } from "react-router-dom";





function App() {

  const [hideNav,setHideNav] = useState( false ) ;
  const [theLogin,setTheLogin] = useState( {} )

  //const navigate = useNavigate() ;


  function onSalir( data  ) // Datos pasados por el login
{
  console.log( "App Salir = " + data  )
}

function onAddMember( member )
{
  console.log( "header " + member.user ) ;
  setTheLogin( member ) ;
}


  return (

    <BrowserRouter>
      <Header hideNav={hideNav} setLogin={theLogin} ></Header>
      <Routes>
        
        {/* <Route path="/" element={<Header />}/> */}
          
        <Route path="/login" element={<Login addMember={onAddMember} hide={(val)=> setHideNav( val )} salir={onSalir}/>} />
        <Route path="/agentes" element={<Agentes hide={(val)=> setHideNav( val )} />} />
        <Route path="/admindash" element={<AdminDash/>} />
        <Route path="/adminagentdash" element={<AdminAgentDash/>} />
        <Route path="/uploadfile" element={<UpLoadFile/>} />
        <Route path="/uploadcalifica" element={<UpLoadCalifica/>} />

        
      </Routes>
    </BrowserRouter>

  );
}

export default App;
